import React, { createContext, useRef, ReactElement } from 'react'
import { AGClientSocket } from 'socketcluster-client'
import initializeSocket from '../library/socket'

interface ISocketProvider {
  children: ReactElement
}

export const SocketContext = createContext<AGClientSocket | undefined>(undefined)

export const SocketProvider = (props: ISocketProvider) => {
  const { children } = props
  const initSocket = initializeSocket()
  const socketRef = useRef<AGClientSocket>()

  if (!socketRef.current) {
    socketRef.current = initSocket
  }
  return (
    <SocketContext.Provider value={socketRef.current}>
      {children}
    </SocketContext.Provider>
  )
}