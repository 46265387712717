import socketCluster, { AGClientSocket } from 'socketcluster-client'

let socket: AGClientSocket

async function onConnect(socket: AGClientSocket): Promise<void> {
  for await (const { id } of socket.listener('connect')) {
    console.log(`socket is connected! : ${id}`)
  }
}

async function onError(socket: AGClientSocket): Promise<void> {
  for await (const { error } of socket.listener('error')) {
    console.log('socket Error', error)
  }
}

function initializeSocket (): AGClientSocket {
  socket = socketCluster.create({
    hostname: 'socketcluster.topspin.space',
    port: 443,
    autoReconnect: true,
    secure: true,
  })
  
  onError(socket)
  onConnect(socket)

  return socket
}

export default initializeSocket