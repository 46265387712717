import React from "react"
import "./App.css"
import useSocket from "./hooks/useSocket"

interface IDJ {
  djId: string,
  djImage: string,
  fullName: string,
  shortName: string,
  status: string,
}
interface IRadioProgram {
  shiftTitle: string
  shiftThumb: string
  shiftStart: string
  shiftEnd: string
  shiftDesc: string,
  description: string,
  dj: Array<IDJ>
}
interface ICatRadioProps {
  nowSongImage: string
  nowSong: string
  nextSong: string
  source: string
  radioProgram: Array<IRadioProgram>
}

function App() {
  const { message } = useSocket<ICatRadioProps>("catradio")
  return (
    <div
      className={`flex justify-center align-middle h-screen items-center bg`}
      style={{
        backgroundImage: "url(" + message?.nowSongImage + ")",
      }}
    >
      <div className="flex flex-col mr-5 z-10">
        <div className="lg:mr-3 md:mr-6 mb-16">
          <h4>
            <b>▹▹ เพลงปัจจุบัน</b>
          </h4>
          <h1 className="text-white lg:text-7xl md:text-3xl sm:text-xl">
            {message?.nowSong || "อยู่ในช่วงดำเนินรายการ"}
          </h1>
        </div>
        <div>
          <h4>
            <span className="text-red-500">⬤</span><b> รายการออกอากาศปัจจุบัน</b>
          </h4>
          <h1 className="text-white lg:text-7xl md:text-3xl sm:text-xl">
            {message?.radioProgram[0]?.shiftTitle}
          </h1>
          <h4>
            {message?.radioProgram[0]?.shiftStart} - {message?.radioProgram[0]?.shiftEnd} | {message?.radioProgram[0]?.description}
          </h4>
        </div>
      </div>
      <div className="flex w-1/2"></div>
    </div>
  )
}

export default App
