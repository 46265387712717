import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../context/SocketProvider.context"

const useSocket = <T extends object> (channelName: string) => {
  const socket = useContext(SocketContext)
  const [message, setMessage] = useState<T>()
  let channel: any

  const _subscribed = async () => {
    channel = socket && socket.subscribe(channelName)
    await channel.listener("subscribe").once()
    console.log("socketcluster subscribe success in channel : " + channelName)
  }

  const _consumeData = async (): Promise<void> => {
    for await (let dataChannel of channel) {
      setMessage(dataChannel)
    }
  }

  useEffect(() => {
    _subscribed()
    _consumeData()
    // eslint-disable-next-line
  }, [channelName,])

  return {
    message,
    channelName
  }
}

export default useSocket
