import * as React from "react";
import { BrowserRouter, Routes as Router, Route } from "react-router-dom"
import App from "../App";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Router>
        <Route path="/" element={<App />} />
      </Router>
    </BrowserRouter>
  )
}

export default Routes
